<template>
  <v-container>
    <v-layout>
      <v-card flat>
        <v-card-text>
          <v-layout wrap>
            <v-flex
              xs12
              mb-1
            >
              <div v-if="candidate.external_site === 'Laborum'">
                <LaborumCandidateCard
                  v-if="candidate"
                  :candidate="candidate"
                  :questions="questions"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import LaborumCandidateCard from '@/components/candidates/LaborumCandidateCard.vue';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'OHCDetail',
  components: {
    LaborumCandidateCard,
  },
  mixins: [JWTConfigMixin],
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ohc: {},
      ohcStates: [],
      extApplicants: [],
      updateDialog: false,
      offerPressed: {},
    };
  },
  methods: {
    reloadOnSuccess() {
      this.updateDialog = false;
      this.$emit('newOhcCreated');
    },
  },
};
</script>
