<template>
  <v-layout v-if="!candidate">
    <v-flex xs12>
      <p>  ...cargando </p>
    </v-flex>
  </v-layout>

  <v-layout
    v-else
    row
    wrap
  >
    <v-flex
      xs12
      mb-1
    >
      <v-card>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md4
              text-xs-center
            >
              <v-avatar size="88">
                <v-img
                  :src="`${profilePic}`"
                  class="mb-4"
                />
              </v-avatar>

              <h3 class="headline mb-2">
                {{ candidate.data.Nombre }} {{ candidate.data.Apellido }}
              </h3>

              <div class="mb-2">
                <a :href="`mailto:${candidate.email}`">{{ candidate.email }}</a>
              </div>

              <div class="subheading font-weight-bold">
                {{ candidate.data.Celular }}
                {{ candidate.data.Telefono }}
              </div>
            </v-flex>

            <v-flex
              xs12
              md8
            >
              <table style="text-align: left">
                <thead />
                <tbody>
                  <tr>
                    <th>Fecha de postulación:</th>
                    <td style="font-size: medium">
                      <p>
                        {{ candidate.data['Fecha Postulación'] }}
                        ({{ candidate.external_site }})
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <th>Fecha de nacimiento:</th>
                    <td style="font-size: medium">
                      {{ candidate.data['Fecha de nacimiento'] }}
                    </td>
                  </tr>

                  <tr>
                    <th>Nacionalidad:</th>
                    <td style="font-size: medium">
                      {{ candidate.data.Nacionalidad }}
                    </td>
                  </tr>

                  <tr>
                    <th>Teléfono:</th>
                    <td style="font-size: medium">
                      {{ candidate.data.Celular }}
                      {{ candidate.data.Telefono }}
                    </td>
                  </tr>

                  <tr>
                    <th>Dirección:</th>
                    <td style="font-size: medium">
                      {{ candidate.data['Dirección'] }}
                    </td>
                  </tr>

                  <tr>
                    <th>Rut:</th>
                    <td style="font-size: medium">
                      {{ candidate.national_id }}
                    </td>
                  </tr>

                  <tr>
                    <th>Salario bruto pretendido :</th>
                    <td style="font-size: medium">
                      {{ candidate.data['Preferencia Salarial'] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      mb-1
    >
      <v-card>
        <v-card-title><h3>Experiencia</h3></v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex>
              <table style="text-align: left">
                <thead />
                <tbody>
                  <div
                    v-for="(row,col) in candidate.data.Experiencia"
                    :key="col"
                  >
                    <div v-if="row.Puesto!==''">
                      <tr>
                        <th>Puesto:</th>
                        <td style="font-size: medium">
                          {{ row.Puesto }}
                        </td>
                      </tr>

                      <tr>
                        <th>Empresa:</th>
                        <td style="font-size: medium">
                          {{ row.Empresa }}
                        </td>
                      </tr>

                      <tr>
                        <th>Pais:</th>
                        <td style="font-size: medium">
                          {{ row['Pais'] }}
                        </td>
                      </tr>

                      <tr>
                        <th>Periodo:</th>
                        <div v-if="row['Fecha Fin']===''">
                          <td style="font-size: medium">
                            {{ row['Fecha Inicio'] }} - Actualidad
                          </td>
                        </div>
                        <div v-else>
                          <td style="font-size: medium">
                            {{ row['Fecha Inicio'] }} - {{ row['Fecha Fin'] }}
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <th>Descripción:</th>
                        <td style="font-size: medium">
                          {{ row['Descripción'] }}
                        </td>
                      </tr>
                      <br>
                    </div>
                  </div>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      mb-1
    >
      <v-card>
        <v-card-title><h3>Estudios</h3></v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex>
              <table style="text-align: left">
                <thead />
                <tbody>
                  <div
                    v-for="(row,col) in candidate.data.Estudios"
                    :key="col"
                  >
                    <div v-if="row.Titulo!==''">
                      <tr>
                        <th>Titulo:</th>
                        <td style="font-size: medium">
                          {{ row.Titulo }}
                        </td>
                      </tr>
                      <tr>
                        <th>Universidad:</th>
                        <td style="font-size: medium">
                          {{ row.Institucion }} ( {{ row.Estado }} - {{ row['Fecha Fin'] }} )
                        </td>
                      </tr>
                      <tr>
                        <th>País:</th>
                        <td style="font-size: medium">
                          {{ row.Pais }}
                        </td>
                      </tr>
                      <br>
                    </div>
                  </div>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      mb-1
    >
      <v-card>
        <v-card-title><h3>Respuestas</h3></v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex>
              <table style="text-align: left">
                <thead />
                <tbody>
                  <div
                    v-for="(row, col) in candidate.answers"
                    :key="col"
                  >
                    <tr>
                      <th>{{ questions.filter(q => (q.id === row.question))[0].question }}:</th>
                      <td style="font-size: medium">
                        {{ row.external_candidates_answers }}
                      </td>
                    </tr>
                  </div>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'CandidateCard',
  mixins: [JWTConfigMixin],
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    profilePic() {
      return 'https://avataaars.io/';
    },
  },
};
</script>
<style scoped>
  div .mb-2 {
    font-size: medium;
  }
</style>
