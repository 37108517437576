<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs3>
        <v-btn
          color="warning"
          @click="$router.push({name: 'admin:offers'})"
        >
          <v-icon left>
            undo
          </v-icon>
          Volver
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <v-spacer />
      </v-flex>
      <v-flex xs3>
        <v-btn
          :disabled="btn_validator"
          color="primary"
          @click="get_extcands()"
        >
          <v-icon
            v-if="btn_loading === false"
            left
          >
            get_app
          </v-icon>
          <v-progress-circular
            v-else
            indeterminate
            :size="30"
            left
            color="white"
          />
          Obtener Candidatos Externos
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="extApplicants"
          hide-actions
          :pagination.sync="pagination"
        >
          <template
            slot="headers"
          >
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                align="left"
                style="font-size: small; font-weight: bold"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td>
              {{ props.item.data.Nombre }}
            </td>
            <td>
              {{ props.item.data.Apellido }}
            </td>
            <td>
              {{ props.item.national_id }}
            </td>
            <td>
              {{ props.item.email }}
            </td>
            <td>
              {{ props.item.external_site }}
            </td>
            <td>
              <v-btn @click="seeOHC(props.item)">
                <v-icon left>
                  insert_comment
                </v-icon>
                Ver
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="extApplicants.length > 0"
      justify-center
      mt-3
    >
      <v-flex xs4>
        <div class="text-xs-center">
          <v-pagination
            v-model="page"
            :length="pages"
            total-visible="5"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-dialog
      v-if="detail_application"
      v-model="detail_application"
      max-width="800"
      persistent
    >
      <v-card
        flat
      >
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ detail_application.data.Nombre }}
          {{ detail_application.data.Apellido }}
          <v-spacer />
        </v-card-title>

        <v-card-text>
          <OHCDetail
            :candidate="detail_application"
            :questions="questions"
            @newOhcCreated="reloadOnSuccess"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="detail_application = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import OHCDetail from '@/components/offers/ExtOHCDetail.vue';

export default {
  name: 'Offers',
  components: {
    OHCDetail,
  },
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      updateDialog: false,
      headers: [
        {
          text: 'Nombres',
        },
        {
          text: 'Apellidos',
        },
        {
          text: 'Rut',
        },
        {
          text: 'E-mail',
        },
        {
          text: 'Portal',
        },
      ],
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
      btn_validator: false,
      btn_loading: false,
      page: 1,
      pages: 1,
      nextPage: '',
      prevPage: '',
      extApplicants: [],
      detail_application: null,
      offerPressed: '',
      longlist_dialog: false,
      ohcChangeData: {
        status: 0,
        uuid: '',
      },
      addohc_dialog: false,
      candidateAdd: '',
      stateAdd: '',
      commentAdd: '',
      candidateList: [],
      wageMin: 0,
      wageMax: 0,
      questions: [],
      answers: {},
    };
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        pageEndpoint = `${this.$store.state.endpoints.offers.admin.extApplicants(this.$route.params.uuid)}?page=${newVal}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplicants();
    });
  },
  methods: {
    loadApplicants() {
      this.$store.commit('setLoading');
      const endpoint = this.$store.state.endpoints.offers.admin.extApplicants(
        this.$route.params.uuid,
      );
      const offep = this.$store.state.endpoints
        .offers.admin.offerQuestions(this.$route.params.uuid);
      axios.get(offep, this.jwtConfig)
        .then(resp => {
          this.questions = resp.data;
        });
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.extApplicants = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.$store.commit('unsetLoading');
          this.setPaginator();
        }).catch(error => {
          this.btn_validator = true;
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp',
            'Esta oferta no fue compartida en portales externos.');
          console.error(error);
        });
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.extApplicants.length;
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.extApplicants = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
    seeOHC(extcand) {
      this.detail_application = extcand;
    },
    get_extcands() {
      this.btn_loading = true;
      this.btn_validator = true;
      const endpoint = this.$store.state.endpoints.offers.admin.getNewExtcands(
        this.$route.params.uuid,
      );
      axios.get(endpoint, this.jwtConfig)
        .then(() => {
          this.btn_loading = false;
          this.btn_validator = false;
          this.loadApplicants();
          this.$store.commit('adminSnackbarUp',
            'Candidatos externos cargados exitosamente.');
        }).catch(error => {
          this.btn_loading = false;
          this.btn_validator = false;
          this.$store.commit('adminSnackbarUp',
            'Hubo un error al intentar obtener los candidatos externos.');
          console.error(error);
        });
    },
    reloadOnSuccess() {
      this.detail_application = null;
      this.loadApplicants();
    },
  },
};
</script>
